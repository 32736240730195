<template>
  <div class="wrap">
    <div class="tips">
      <div class="content">
        当前位置：<span
          @click="
            () => {
              this.$router.push('/');
            }
          "
          >网站首页</span
        >>><span
          @click="
            () => {
              this.$router.push('/galleryPavilion');
            }
          "
          >展厅展馆</span
        >>><span>{{ tabName }}</span>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <div class="text">{{ list.title }}</div>
        <div class="time">时间：{{ list.addtime }}</div>
      </div>
      <div class="description" v-html="list.content"></div>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      tabName: this.$route.query.name,
      list: {},
    };
  },
  created() {},
  methods: {
    getDetal() {
      const that = this;
      that.$http
        .postApi("/qx_show_room_detail", { id: that.$route.query.item })
        .then((res) => {
          console.log("123123", that.$route.query.item);
          if (res.data.code === 200) {
            console.log(res);
            that.list = res.data.data;
            console.log("that.list", that.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getDetal();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  span {
    cursor: pointer;
  }
}
.title {
  margin-top: 89px;
  margin-bottom: 60px;
  text-align: center;
  .text {
    font-size: 30px;
    color: #333;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 29px;
  }
  .time {
    font-size: 20px;
    color: #999;
  }
}
.description {
  margin-bottom: 69px;
  font-size: 24px;
  line-height: 1.05;
  color: #666;
  text-align: left;
  text-indent: 2em;
}
img {
  width: 100%;
  height: 605px;
  margin-bottom: 46px;
}
</style>