<template>
  <div>
    <div class="beijingt">
      <img src="@/assets/qixingp/e_banner@2x.png" alt="" />
    </div>
    <div class="wrap">
      <div class="tips">
        <div class="content">
          当前位置：<span
            @click="
              () => {
                this.$router.push('/');
              }
            "
            >网站首页</span
          >>><span>展览展示</span>
          <!-- >><span>{{ tabName }}</span> -->
        </div>
      </div>
      <div class="content mat">
        <div
          class="ccde"
          v-for="v in tableData"
          :key="v.id"
          @click="toDetail(v.id)"
        >
          <div class="pic">
            <img :src="'http://website.yuangtech.com' + v.cover" alt="" />
          </div>
          <div class="word">
            <p>{{ v.title }}</p>
          </div>
        </div>
        <div class="pageNav">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="9"
            prev-text="<上一页"
            next-text="下一页>"
            layout="prev, pager, next, total, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import x from ''
import getCate from "@/utils/getCate";
export default {
  components: {},
  data() {
    return {
      tabIndex: 1,
      tabName: "",
      hallType: 1,
      typeList: [],
      tableData: [],
      page: 1,
      total: 0,
      title: {
        top: "数字展厅展馆",
        bottom: "数字型、营销型、成果型展厅展馆缔造者",
      },
    };
  },
  created() {},
  methods: {
    switchTab(id) {
      // let {id, cate_name} = item
      let tmpID = id || 1;
      let { cate_name } = this.typeList.find((x) => x.id === Number(tmpID));
      this.tabIndex = Number(tmpID);
      this.tabName = cate_name;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({
        path: "/ExhibitionsDetail",
        query: { item: id },
      });
    },
    getType() {
      const that = this;
      getCate(1).then((res) => {
        if (res.code === 200) {
          console.log(res);
          that.typeList = res.data;
          that.switchTab(this.$route.query.item);
        }
      });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 9,
      };
      that.$http
        .postApi("/qx_exhibition_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // this.getData()
    this.$bus.$emit("setTit", this.title);
    this.getType();
    // this.tabIndex = this.$route.query.item
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  span {
    cursor: pointer;
  }
}
.tab {
  display: flex;
  justify-content: space-between;
  margin: 48px 0px 72px;
  padding: 0 10px;
  border-bottom: 1px solid #e6e6e6;
  .tabItem {
    width: 457px;
    height: 54px;
    font-size: 20px;
    color: #333;
    line-height: 54px;
    text-align: center;
    cursor: pointer;
  }
  .link {
    background-color: #e60412;
    color: #fff;
  }
}
.ccde {
  display: inline-block;
  width: 350px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #cccccc;
  margin-right: 27px;
  margin-bottom: 32px;
  &:nth-child(3n) {
    margin-right: 0px;
  }
  .pic {
    width: 100%;
    height: 269px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transition: all 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .word {
    padding: 20px 0 5px 0;
    // background: #fff;
    p {
      font-size: 19px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #333333;
      line-height: 41px;
    }
  }
}
.mat {
  margin-top: 79px;
}
.beijingt {
  width: 100%;
  height: 471px;
  img {
    width: 100%;
    height: 100%;
  }
}
.pageNav {
  margin: 35px 0 71px;
  text-align: right;
  .el-pagination {
    /deep/.el-pager li {
      border: 1px solid #dbdbdb;
      width: 40px;
      height: 40px;
      position: relative;
      line-height: 40px;
      margin-left: -1px;
      font-weight: 500;
      font-size: 18px;
      &:hover {
        border-color: #1156be;
        z-index: 1;
        color: #1156be;
      }
    }
    /deep/.el-pager li.active {
      border-color: #1156be;
      z-index: 1;
      color: #1156be;
    }
    /deep/button:hover {
      border-color: #1156be;
      color: #1156be;
    }
    /deep/button:disabled {
      color: #c0c4cc;
      background-color: #fff;
      border-color: #dbdbdb;
      cursor: not-allowed;
    }
    /deep/.btn-prev,
    /deep/.btn-next {
      width: 80px;
      height: 40px;
      border: 1px solid #dbdbdb;
    }
    /deep/.btn-prev {
      margin-right: 6px;
    }
    /deep/.btn-next {
      margin-left: 5px;
      margin-right: 23px;
    }
    /deep/button,
    /deep/span:not([class*="suffix"]) {
      line-height: 40px;
    }
  }
}
</style>
