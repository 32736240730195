<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>中兴新动能展</p>
        <span>时间：2021年08月25日</span>
        <div class="ccde">
          <p>
            2021新动能·青岛展洽会以“新格局·新动能·新机遇”为主题，采取会议与展览结合、线下实体展与线上云展结合、展场互动与推介洽谈结合的方式，展示山东新旧动能转换成果，产业优势和发展机遇，展示跨国公司、龙头企最新产品技术合作需求，展示山东省与跨国公司合作成果，充分发掘峰会参与企业资源，促进山东省与跨国公司合作，提高山东产业与世界的融合度，持续放大峰会效应。
          </p>
          <img src="@/assets/qixingp/矩形 13.png" alt="" />
          <img src="@/assets/qixingp/矩形 13.png" alt="" />
        </div>
      </div>
    </template>
    <bottom></bottom>
  </div>
</template>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {},
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 12,
        module_id: 2,
      };
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #595757;
    text-align: center;
  }
  span {
    display: block;
    width: 8.0625rem;
    font-size: 7px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #8b8b8b;
    margin: 0 auto;
  }
  .ccde {
    display: inline-block;
    margin: 0 auto;
    width: 19.281rem;
    // background: #fff;
    margin-top: 0.9375rem;
    padding-bottom: 1.4375rem;
    p {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #595757;
      text-indent: 2em;
      text-align: left;
    }
    img {
      width: 18.5625rem;
      height: 9.3125rem;
      margin-top: 0.9375rem;
    }
  }
}
</style>