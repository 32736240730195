<template>
  <div class="introduction">
    <Header></Header>
    <div class="tips">
      <div class="content">当前位置：<span @click="$router.push('/')">网站首页</span> >> 公司简介</div>
    </div>
    <div class="content textCenter">
      <h3>
        公司简介<br />
        <span>COMPANY PROFILE</span>
      </h3>
      <div>
        <p>
          青岛佳朋展览展示有限公司，总部设在滨海城市青岛，下设济南分公司和潍坊分公司，是一家专业从事国内外展览展示设计、展厅装饰、展台搭建、商业空间设计装饰、工程装饰以及活动会务庆典策划搭建等服务的综合性展示服务机构。我公司为山东省会展协会会员单位,青岛市装饰协会会员单位,青岛市国际会展中心会员单位,具有施工承包二级资质。
        </p>
        <p>
          我公司秉承"专业服务，雕琢精品，有限空间，无限创意"的服务宗旨，以客户至上，人才为本，精细化服务的经营理念为宗旨，不断提升佳朋品牌的美誉度。公司自成立以来多次为政府机构、企事业单位等各领域客户提供一流的展览展示服务，用有限的空间，赢取无限的创意，以高端的制作品质，合理的价格，优质的服务闻名于业界，赢得了客户的一致好评
        </p>
        <p>
          我公司拥有一支专业、高素质的复合型展览服务团队，辅以公司独特的专业技能培训和完善的展览操作监管流程，为客户提供最佳展示效果和最优会展服务，为客户量身打造最具行业领袖特质的个性化商业展示空间。公司和德国的展览设计搭建公司保持紧密的合作关系，及时把国外现代时尚的设计理念带到国内，更好的为客户服务。
        </p>
        <p>
          我公司自有展览制作工厂面积达3000余平米，拥有美工、木工，油漆工，铁工，电工等专业的技工人员30余人，全部持证上岗，80%以上人员施工经验超过10年。后备的各种专业技术人员100多人，由公司统一管理。工厂拥有齐全的各种大型木业机器，雕刻机，写真喷绘机，升降机等专业加工机械设备，配置了木工车间，烤漆房，铁工部，美工部等，可以高质量的完成喷漆、木工、电焊、金属制品、美工等工序，为高质高效的完成每一个项目提供了强有力的保障
        </p>
        <p>
          我们坚信：只有诚信为先，服务至上，力求完美，才能达到客户的满意和成功，才能彰显我们在业内的优势和实力，才能持续创新不断进步。我们始终认为客户的满意仅是我们的新起点，我们愿意通过自身的不懈努力，成为企事业客商展览展示开拓市场、迅速扩大客户市场份额、树立企业完美形象的助手和参谋。青岛佳朋展览展示公司真诚期待与您携手，共筑发展空间，构造美好未来。
        </p>
      </div>
      <div class="worth">核心价值</div>
      <div class="textBot">
        <p>
          <span>成就客户：</span
          >为客户提供服务，是我们存在的价值；快速有效的解决客户需求，是我们始终追求的目标。
        </p>
        <p>
          <span>至诚守信：</span
          >诚信是企业发展的基石，失去诚信，也就拗断了生意的长久之道、失去了团队的全力支持。
        </p>
        <p>
          <span>团结合作：</span
          >没有完美的个人，只有完美的团队，注重团队合作才是提高流程效率的有效保障。
        </p>
        <p>
          <span>创新实现：</span
          >内部工作的创新、为解决客户需求的方案创新是实时需要的，同时要快速有效的加以实现。
        </p>
        <p>
          <span>艰苦奋斗：</span
          >以奋斗为本，通过为客户创造价值的艰苦奋斗过程提高自己并实现价值，赢得客户的尊重和信赖。
        </p>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import Header from "@/components/header1";
import bottom from "@/components/bottom";
export default {
  components: { Header, bottom },
  data() {
    return {
      title: {
        top: '党政成果展厅',
        bottom: '数字型、多视角、成果型主题展馆'
      }
    }
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
  }
};
</script>

<style lang="scss" scoped>
.introduction {
  .tips {
    background-color: #eee;
    height: 78px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 78px;
  }
  .textCenter {
    h3 {
      margin-top: 75px;
      margin-bottom: 91px;
      font-size: 34px;
      font-weight: bold;
      text-align: center;
      color: #333333;
      letter-spacing: 2px;
      line-height: 35px;
      span {
        font-size: 16px;
        color: #999999;
      }
    }
    p {
      font-size: 18px;
      text-align: left;
      color: #999999;
      line-height: 23px;
      text-indent: 2em;
      letter-spacing: 1px;
    }
    .worth {
      width: 132px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #e60412;
      border-radius: 0px 10px 0px 10px;
      font-size: 18px;
      font-weight: 700;
      color: #fffefe;
      letter-spacing: 1px;
      margin: 46px 0 35px;
    }
    .textBot {
      margin-bottom: 177px;
      p {
        text-indent: 0em;
        line-height: 28px;
        span {
          color: #666666;
        }
      }
    }
  }
}
</style>
