<template>
  <div class="page1">
    <div class="header">
      <div class="imgs">
        <img src="@/assets/qixingp/组 32@2x.png" alt="" />
      </div>
      <div class="phone">
        <img src="@/assets/qixingp/矢量智能对象@2x.png" alt="" />
        <span>
          <p>七星客服电话</p>
          <p>400-966-0622</p>
        </span>
      </div>
      <div class="click">
        <img
          src="@/assets/qixingp/组 12@2x.png"
          @click="pushRouter('/Aboutus_s')"
          alt=""
        />
      </div>
      <!-- <transition>
        <div class="down">
          <ul>
            <li>关于我们</li>
            <li>环保展具</li>
            <li>展厅展馆</li>
            <li>展览展示</li>
            <li>线上展厅</li>
            <li>展具商城</li>
            <li>联系我们</li>
          </ul>
        </div>
      </transition> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: {},
      isShow: true,
    };
  },
  methods: {
    pushRouter(item) {
      this.$router.push(item);
    },
  },
  mounted() {
    this.$bus.$on("setTit", (data) => {
      this.titles = data;
    });
  },
  beforeDestroy() {
    this.$bus.$off("setTit");
  },
};
</script>

<style lang="scss" scoped>
// 动画实验
// h2 {
//   background: orange;
// }

// .v-enter-active {
//   animation: slidein 1s linear;
// }
// .v-leave-active {
//   animation: slidein 1s linear reverse;
// }
// @keyframes slidein {
//   from {
//     transform: translatey(-100%);
//   }
//   to {
//     transform: translatey(0px);
//   }
// }
// 头部
.contact {
  img {
    display: inline-block;
    margin-left: 20px;
  }
  span {
    display: inline-block;
    margin-left: 13px;
    width: 114px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    // font-weight: 700;
    text-align: left;
  }
}
.page1 {
  position: relative;
  .header {
    display: flex;
    justify-content: center;
    // height: 100px;
    padding: 0.25rem 0 0.75rem 0;
    // background-color: #fff;
    .imgs {
      display: inline-block;
      width: 3.85625rem;
      height: 1.95rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .phone {
      display: inline-block;
      margin-left: 5.5rem;
      img {
        width: 1.4375rem;
        height: 1.4375rem;
      }
      span {
        display: inline-block;
        p {
          &:nth-child(1) {
            font-size: 7px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #595757;
          }
          &:nth-child(2) {
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #0059ab;
          }
        }
      }
    }
    .click {
      display: inline-block;
      margin-left: 5rem;
      margin-top: 0.625rem;
      img {
        width: 1.16875rem;
        height: 0.95rem;
      }
    }
  }
}
// .down {
//   width: 100%;
//   height: 100%;
//   ul {
//     li {
//       display: block;
//       border-top: 1px solid #595757;
//       padding: 5rem 0 5rem 10rem;
//       &:nth-child(7) {
//         border-bottom: 1px solid #595757;
//       }
//     }
//   }
// }
</style>