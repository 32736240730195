<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>展厅展馆</p>
        <span class="xians"></span>
        <div class="ccde">
          <div class="pic">
            <img src="@/assets/qixingp/矩形 802 拷贝.png" alt="" />
          </div>
          <div class="word">
            <p>文化展厅</p>
          </div>
        </div>
        <div class="ccde">
          <div class="pic">
            <img src="@/assets/qixingp/矩形 802 拷贝.png" alt="" />
          </div>
          <div class="word">
            <p>文化展厅</p>
          </div>
        </div>
      </div>
    </template>
    <bottom></bottom>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {},
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 12,
        module_id: 2,
      };
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0059ab;
    text-align: center;
  }
  .ccde {
    display: inline-block;
    margin: 0 auto;
    width: 9.043rem;
    margin-top: 1.375rem;
    // background: #fff;
    &:nth-child(2n-1) {
      margin-left: 0.5625rem;
    }
    .pic {
      width: 9.043rem;
      height: 7.2625rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .word {
    p {
      display: block;
      background-color: #0059ab;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #fefefe;
    }
  }
}
.pics {
  display: block;
  width: 6.625rem;
  height: 1.95rem;
  margin: 0 auto;
  margin-top: 2.625rem;
}
.case_i {
  width: 100%;
  height: 10rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.xians {
  display: block;
  width: 31px;
  height: 2px;
  background: #0059ab;
  margin: 0 auto;
  margin-top: 0.4375rem;
}
</style>