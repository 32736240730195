<template>
  <div class="wrap">
    <div class="tips">
      <div class="content">
        当前位置：<span
          @click="
            () => {
              this.$router.push('/');
            }
          "
          >网站首页</span
        >>><span
          @click="
            () => {
              this.$router.push('/modularization');
            }
          "
          >模块化展具</span
        >>>模块化展具详情
      </div>
    </div>
    <div class="content wrapBox" v-html="list.content">
      <!-- <img src="../../assets/modularization/1.jpg" alt="" />
      <img src="../../assets/modularization/1-2.jpg" alt="" /> -->
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      tabName: "企业展厅",
      list: {},
    };
  },
  created() {},
  methods: {
    getDetal() {
      const that = this;
      that.$http
        .postApi("/data_detail", { id: that.$route.query.item })
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.list = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    window.scrollTo(0,0);
    this.getDetal();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  margin-bottom: 80px;
  span {
    cursor: pointer;
  }
}
.wrapBox {
  margin-bottom: 59px;
  /deep/img {
    max-width: 100%;
    // min-height: 642px;
    margin-bottom: 59px;
  }
}
</style>
