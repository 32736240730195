import { render, staticRenderFns } from "./Cultural.vue?vue&type=template&id=00c8f9b9&scoped=true&"
import script from "./Cultural.vue?vue&type=script&lang=js&"
export * from "./Cultural.vue?vue&type=script&lang=js&"
import style0 from "./Cultural.vue?vue&type=style&index=0&id=00c8f9b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c8f9b9",
  null
  
)

export default component.exports