<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>{{ list.title }}</p>
        <span>时间：{{ list.addtime }}</span>
        <div class="ccde">
          <p v-html="list.content"></p>
          <img :src="'http://website.yuangtech.com' + list.cover" alt="" />
        </div>
      </div>
    </template>
    <bottom></bottom>
  </div>
</template>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      list: [],
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {},
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      that.$http
        .postApi("/qx_zj_plan_detail", { id: that.$route.query.item })
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.list = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #595757;
    text-align: center;
  }
  span {
    display: block;
    width: 10rem;
    font-size: 7px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #8b8b8b;
    margin: 0 auto;
  }
  .ccde {
    display: inline-block;
    margin: 0 auto;
    width: 19.281rem;
    // background: #fff;
    margin-top: 0.9375rem;
    padding-bottom: 1.4375rem;
    p {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #595757;
      text-indent: 2em;
      text-align: left;
      display: block;
      width: 18.5rem !important;
      overflow: hidden;
      ::v-deep img {
        display: none;
      }
    }
    img {
      width: 18.5625rem;
      height: 9.3125rem;
      margin-top: 0.9375rem;
    }
  }
}
</style>