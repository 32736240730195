<template>
  <div class="wrap">
    <div class="tips">
      <div class="content">
        当前位置：<span
          @click="
            () => {
              this.$router.push('/');
            }
          "
          >网站首页</span
        >>><span>模块化展具</span>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <div
          class="item"
          @click="toDetail(s.id)"
          v-for="s in tableData"
          :key="s.id"
        >
          <div class="img">
            <img :src="'http://website.yuangtech.com' + s.cover" alt="" />
          </div>
          <div class="title">{{ s.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      page: 1,
      tableData: [],
      total: 0,
      title: {
        top: '环保展具倡导者',
        bottom: '模块化展具，低碳环保，安全高效，多快好省'
      }
    };
  },
  created() {},
  methods: {
    toDetail(id) {
      this.$router.push({ path: "/modulDetail", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 99,
        module_id: 4,
      };
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  margin-bottom: 53px;
  span {
    cursor: pointer;
  }
}
.list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item {
    margin-right: 22px;
    width: 385px;
    height: 344px;
    border: 1px solid #cccccc;
    padding: 15px 15px 15px;
    box-sizing: border-box;
    margin-bottom: 42px;
    cursor: pointer;
    .img {
      width: 100%;
      height: 269px;
      background-color: #eee;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.6s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .title {
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      color: #333;
    }
  }
  .item:nth-child(3n) {
    margin-right: 0;
  }
}
</style>