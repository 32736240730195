<template>
  <div class="page1">
    <div class="logo">
      <div class="left">
        <img src="../assets/qixing/qixinglogo.png" alt="" />
      </div>
      <div class="right">
        <img src="../assets/qixing/矢量智能对象@2x.png" alt="" />
        <div class="word">
          <p>七星客服电话</p>
          <span>400 9660622</span>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="content headCentet">
        <ul>
          <li @click="pushRouter('/index_p')">首页</li>
          <!-- galleryPavilion, 1 -->
          <li @click="pushRouter('/cultural')">关于我们</li>
          <!-- exhibition -->
          <!-- <li @click="pushRouter('/exhibitionDetail')">环保展具</li> -->
          <li @click="pushRouter('/exhibition')">环保展具</li>
          <!-- cultural -->
          <li @click="pushRouter('/galleryPavilion', 1)">展厅展馆</li>
          <li @click="pushRouter('/Exhibitions')">展览展示</li>
          <!-- modularization -->
          <!-- newsCenter -->
          <!-- About -->
          <li @click="cimsInputClick()">展具商城</li>
          <li @click="pushRouter('/newsCenter')">新闻中心</li>
          <li @click="pushRouter('/About')">联系我们</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: {},
    };
  },
  methods: {
    cimsInputClick() {
      window.open("http://shop.zhanju365.com/", "_blank");
    },
    pushRouter(item) {
      this.$router.push(item);
    },
  },
  mounted() {
    this.$bus.$on("setTit", (data) => {
      this.titles = data;
    });
  },
  beforeDestroy() {
    this.$bus.$off("setTit");
  },
};
</script>

<style lang="scss" scoped>
// 头部
.contact {
  img {
    display: inline-block;
    margin-left: 20px;
  }
  span {
    display: inline-block;
    margin-left: 13px;
    width: 114px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    // font-weight: 700;
    text-align: left;
  }
}
.page1 {
  position: relative;
  .header {
    height: 100px;
    // background-color: #fff;
    .headCentet {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0059ab;
      width: 100%;
      ul {
        display: flex;
        li {
          font-size: 18px;
          // color: #ffffff;
          line-height: 26px;
          width: 150px;
          text-align: center;
          line-height: 100px;
          color: #fff;
          cursor: pointer;
          &:hover {
            background: #f39700;
          }
          a {
            color: #fff;
          }
        }
        .act {
          background: #e60412;
        }
      }
    }
  }
  .theme {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h3 {
      font-size: 72px;
      font-weight: 700;
      color: #ffffff;
      line-height: 110px;
      letter-spacing: 2px;
      margin-bottom: 30px;
    }
    p {
      font-size: 38px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 70px;
      letter-spacing: 1px;
      margin-bottom: 118px;
    }
    .arrow {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      border: 1px solid #fff;
      margin: 0 auto;
      line-height: 71px;
      img {
        vertical-align: middle;
      }
    }
  }
}
// .tips {
//   background-color: #eee;
//   height: 78px;
//   font-size: 18px;
//   font-weight: 500;
//   text-align: left;
//   color: #333333;
//   line-height: 78px;
// }
.logo {
  height: 100px;
  .left {
    float: left;
    margin-left: 186px;
    margin-top: 20px;
    // margin-bottom: 20px;
    img {
      width: 128px;
      height: 63px;
    }
  }
  .right {
    float: right;
    margin-right: 186px;
    margin-top: 20px;
    // margin-bottom: 20px;
    img {
      width: 66px;
      height: 66px;
      display: inline-block;
    }
    .word {
      display: inline-block;
      margin-left: 10px;
      p {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #595757;
      }
      span {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #0059ab;
      }
    }
  }
}
</style>
