import http from "./api";
const getCate = function (types) {
  return http.postApi('/cate_list', {
    type: types
  }).then(res => {
    return res.data
  }).catch(err => {
    console.log(err)
  })
}
export default getCate