<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>展厅展馆</p>
        <span class="xians"></span>
        <div class="blacks">
          <div
            v-for="item in typeList"
            :key="item.id"
            :class="tabIndex == item.id ? 'tabItem  link' : 'tabItem '"
            @click="switchTab(item.id)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>

        <div
          class="ccde"
          @click="toDetail(v.id)"
          v-for="v in tableData"
          :key="v.id"
        >
          <div class="pic">
            <img :src="'http://website.yuangtech.com' + v.cover" alt="" />
          </div>
          <div class="word">
            <p>{{ v.title }}</p>
          </div>
        </div>
      </div>
    </template>
    <bottom></bottom>
  </div>
</template>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      tabIndex: 1,
      tabName: "",
      hallType: 1,
      typeList: [],
      tableData: [],
      page: 1,
      total: 0,
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {
    this.getData();
    this.getDatas();
  },
  methods: {
    switchTab(id) {
      // let {id, cate_name} = item
      let tmpID = id || 1;
      let { name } = this.typeList.find((x) => x.id === Number(tmpID));
      this.tabIndex = Number(tmpID);
      this.tabName = name;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({
        path: "/exhibitionHallDetail_s",
        query: { item: id },
      });
    },
    // getType() {
    //   const that = this;
    //   getCate(1).then((res) => {
    //     if (res.code === 200) {
    //       console.log("111122", res);
    //       // that.typeList = res.data;
    //       that.switchTab(this.$route.query.item);
    //     }
    //   });
    // },
    getDatas() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_show_room_type", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log("typw", res.data);
            that.typeList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 9,
        cate_id: that.tabIndex,
        module_id: 1,
      };
      that.$http
        .postApi("/qx_show_room_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // this.getData()
    this.$bus.$emit("setTit", this.title);
    // this.getType();
    // this.tabIndex = this.$route.query.item
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0059ab;
    text-align: center;
  }
  .ccde {
    display: inline-block;
    margin: 0 auto;
    width: 9.043rem;
    margin-top: 1.375rem;
    // background: #fff;
    &:nth-child(2n-1) {
      margin-left: 0.5625rem;
    }
    .pic {
      width: 9.043rem;
      height: 7.2625rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .word {
    p {
      display: block;
      background-color: #0059ab;
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #fefefe;
    }
  }
}
.pics {
  display: block;
  width: 6.625rem;
  height: 1.95rem;
  margin: 0 auto;
  margin-top: 2.625rem;
}
.case_i {
  width: 100%;
  height: 10rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.xians {
  display: block;
  width: 31px;
  height: 2px;
  background: #0059ab;
  margin: 0 auto;
  margin-top: 0.4375rem;
}
.blacks {
  background: #f7f8f8;
  width: 100%;
  span {
    display: inline-block;
    width: 4.8rem;
    height: 1.875rem;
    line-height: 1.875rem;
    text-align: center;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    &:hover {
      background: #0059ab;
      color: #fff;
    }
  }
}
</style>