<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>联系我们</p>
        <span class="xians"></span>
        <img src="../../assets/qixingp/ditu@2x.png" class="ditu" alt="" />
        <!-- <img src="@/assets/qixingp/矩形 984.png" alt="" class="ditu" /> -->
        <div class="ccde">
          <div class="tops">
            <img src="@/assets/qixingp/矢量智能对象s@2x.png" alt="" />
            <span>地址: </span>
            <p class="posa">{{ tableData.address }}</p>
          </div>
          <div class="tops">
            <img src="@/assets/qixingp/组 30@2x.png" alt="" />
            <span>电话: </span>
            <p>{{ tableData.phone }}</p>
          </div>
          <div class="tops">
            <img src="@/assets/qixingp/组 29@2x.png" alt="" />
            <span>邮箱: </span>
            <p>{{ tableData.email }}</p>
          </div>
        </div>
      </div>
    </template>
    <template>
      <img src="" alt="" />
    </template>
    <bottom></bottom>
  </div>
</template>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {},
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_company_phone", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data;
            console.log("电话", that.tableData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0059ab;
    text-align: center;
  }
  .ccde {
    display: block;
    margin: 0 auto;
    width: 19.281rem;
    margin-top: 1.375rem;
    position: relative;
    img {
      display: inline-block;
      width: 1.6125rem;
      height: 1.6125rem;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #595757;
      line-height: 14px;
      width: 12rem;
      text-align: left;
      margin-left: 0.3125rem;
    }
    span {
      width: 2rem;
      display: inline-block;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #595757;
      line-height: 14px;
      text-align: left;
      margin-left: 0.3125rem;
    }
  }
}
.case_i {
  width: 100%;
  height: 10rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.xians {
  display: block;
  width: 31px;
  height: 2px;
  background: #0059ab;
  margin: 0 auto;
  margin-top: 0.4375rem;
}
.ditu {
  width: 100%;
  height: 10.2rem;
  margin-top: 0;
}
.tops {
  margin-top: 0.75rem;
}
.posa {
  position: absolute;
  top: 0rem;
}
</style>