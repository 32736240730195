<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Bottom from "../../components/bottom.vue";
import Header from "../../components/header1.vue";
export default {
  components: { Bottom, Header },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="" scoped>
</style>