<template>
  <div class="introduction">
    <Header></Header>
    <div class="beijingt">
      <img src="@/assets/qixingp/new_banner@2x.png" alt="" />
    </div>
    <div class="tips" v-if="$route.path === '/newsCenter'">
      <div class="content">
        当前位置：<span @click="$router.push('/')">网站首页</span> >> 新闻中心
      </div>
    </div>
    <div class="content textCenter" v-if="$route.path === '/newsCenter'">
      <ul>
        <li v-for="item in tableData" :key="item.id" @click="toDetail(item.id)">
          <div class="redBox">
            <h4>{{ item.day }}</h4>
            <h5>{{ item.yeah_month }}</h5>
          </div>
          <div class="textBox">
            <h3>{{ item.title }}</h3>
            <p v-html="item.content"></p>
          </div>
        </li>
      </ul>
      <div class="pageNav">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="8"
          prev-text="<上一页"
          next-text="下一页>"
          layout="prev, pager, next, total, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <Detail v-if="$route.path === '/newsDetail'"></Detail>
    <bottom></bottom>
  </div>
</template>

<script>
import Header from "@/components/header1";
import bottom from "@/components/bottom";
import Detail from "@/views/news/detail";
import getCate from "@/utils/getCate";
export default {
  data() {
    return {
      activeName: "second",
      page: 1,
      currIndex: 3,
      tabs: [],
      tableData: [],
      total: 0,
      title: {
        top: "党政成果展厅",
        bottom: "数字型、多视角、成果型主题展馆",
      },
    };
  },
  components: { Header, bottom, Detail },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    switchType(id) {
      this.currIndex = id;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({ path: "/newsDetail", query: { item: id } });
    },
    getType() {
      const that = this;
      getCate(5).then((res) => {
        if (res.code === 200) {
          that.tabs = res.data;
        }
      });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 8,
        cate_id: that.currIndex,
        module_id: 5,
      };
      that.$http
        .postApi("/qx_news_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            // that.tableData = res.data.data.list;
            that.total = res.data.data.count;
            res.data.data.list.map((item) => {
              item.content = item.content
                .replace(/\s*/g, "")
                .replace(/<[^>]+>/g, "")
                .replace(/↵/g, "")
                .replace(/[\r\n]/g, "")
                .replace(/\\,/g, "</br>")
                .replace(/&nbsp;/g, " ")
                .replace(/<\/?(img|table)[^>]*>/g, ""); //去除图片、表格
              this.tableData.push(item);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getType();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  .tips {
    background-color: #eee;
    height: 78px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 78px;
    span {
      cursor: pointer;
    }
  }
  .textCenter {
    .tabs {
      border-bottom: 1px dashed #e6e6e6;
      padding: 33px 56px 0;
      display: flex;
      .tabs-item {
        width: 157px;
        height: 54px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        line-height: 54px;
        letter-spacing: 1px;
        cursor: pointer;
      }
      .tabs-item.act {
        background: #1156be;
        color: white;
      }
    }
    ul {
      margin-top: 15px;
      li {
        padding: 57px 1% 43px 56px;
        display: flex;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
        .redBox {
          width: 104px;
          height: 103px;
          background: #1156be;
          color: #ffffff;
          line-height: 32px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h4 {
            font-size: 36px;
            font-weight: 800;
            letter-spacing: 2px;
          }
          h5 {
            font-size: 20px;
            font-weight: 500;
          }
        }
        .textBox {
          margin-left: 48px;
          flex: 1;
          h3 {
            font-size: 24px;
            font-weight: 700;
            color: #333333;
            line-height: 28px;
            margin-bottom: 22px;
            cursor: pointer;
          }
          p {
            font-size: 14px;
            color: #999999;
            line-height: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 2行 */
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    .pageNav {
      margin: 35px 0 71px;
      text-align: right;
      .el-pagination {
        /deep/.el-pager li {
          border: 1px solid #dbdbdb;
          width: 40px;
          height: 40px;
          position: relative;
          line-height: 40px;
          margin-left: -1px;
          font-weight: 500;
          font-size: 18px;
          &:hover {
            border-color: #1156be;
            z-index: 1;
            color: #1156be;
          }
        }
        /deep/.el-pager li.active {
          border-color: #1156be;
          z-index: 1;
          color: #1156be;
        }
        /deep/button:hover {
          border-color: #1156be;
          color: #1156be;
        }
        /deep/button:disabled {
          color: #c0c4cc;
          background-color: #fff;
          border-color: #dbdbdb;
          cursor: not-allowed;
        }
        /deep/.btn-prev,
        /deep/.btn-next {
          width: 80px;
          height: 40px;
          border: 1px solid #dbdbdb;
        }
        /deep/.btn-prev {
          margin-right: 6px;
        }
        /deep/.btn-next {
          margin-left: 5px;
          margin-right: 23px;
        }
        /deep/button,
        /deep/span:not([class*="suffix"]) {
          line-height: 40px;
        }
      }
    }
  }
}
.beijingt {
  width: 100%;
  height: 471px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
