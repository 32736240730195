<!--
 * @Author: yumingkai 2543471816@qq.com
 * @Date: 2022-07-25 10:11:09
 * @LastEditors: yumingkai 2543471816@qq.com
 * @LastEditTime: 2022-08-10 17:04:32
 * @FilePath: \qixing-web\src\views\Mobileterminal\Aboutus.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="imgs">
      <img src="@/assets/qixingp/组 33@2x.png" alt="" @click="returns" />
    </div>
    <div class="down">
      <ul>
        <li @click="pushRouter('/index')">首页</li>
        <li @click="pushRouter('/About_us_s')">关于我们</li>
        <li @click="pushRouter('/exhibitions_s')">环保展具</li>
        <li @click="pushRouter('/exhibitionHall_s')">展厅展馆</li>
        <li @click="pushRouter('/Exhibition_s')">展览展示</li>
        <li>线上展厅</li>
        <li @click="cimsInputClick()">展具商城</li>
        <li @click="pushRouter('/contactus_s')">联系我们</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
  methods: {
    cimsInputClick() {
      window.open("http://shop.zhanju365.com/", "_blank");
    },
    returns() {
      this.$router.go(-1);
    },
    pushRouter(item) {
      this.$router.push(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.imgs {
  position: relative;
  height: 60px;
  img {
    position: absolute;
    top: 1.5rem;
    right: 5rem;
    width: 1.10625rem;
    height: 1.10625rem;
  }
}
.down {
  width: 100%;
  height: 100%;
  ul {
    li {
      display: block;
      border-top: 1px solid #595757;
      padding: 1.2rem 0 1.2rem 2.5rem;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #3e3a39;
      &:nth-child(8) {
        border-bottom: 1px solid #595757;
      }
    }
  }
}
</style>