<template>
  <div class="wrap">
    <div class="tips">
      <div class="content">
        当前位置：<span
          @click="
            () => {
              this.$router.push('/');
            }
          "
          >网站首页</span
        >>><span>关于我们</span>
      </div>
    </div>
    <div class="content clearfix">
      <div class="list clearfix">
        <div class="left">
          <span></span>
          <span @click="pushRouter('/Cultural')">公司介绍</span>
          <span @click="pushRouter('/CulturalDetail')">走进党建</span>
          <div class="loca"><p>走进七星</p></div>
        </div>
        <div class="right">
          <img src="@/assets/qixing/tu7.png" alt="" />
          <p class="p1">走进党建</p>
          <p class="p2">Into Party Building</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      title: {
        top: "文化建设先行者",
        bottom: "深度发掘文化内涵，提炼展示亮点，打造专属特色文化",
      },
    };
  },
  created() {},
  methods: {
    pushRouter(item) {
      this.$router.push(item);
    },
    toDetail(id) {
      this.$router.push({ path: "/culturalDetail", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {
        page: 1,
        limit: 10000,
        module_id: 3,
      };
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  span {
    cursor: pointer;
  }
}
.list {
  width: 1200px;
  margin: 0 auto;
  padding-top: 48px;
  .left {
    position: relative;
    float: left;
    display: inline-block;
    width: 260px;
    box-shadow: 0px 0px 20px 0px rgba(47, 49, 51, 0.08);
    span {
      display: block;
      width: 260px;
      height: 64px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #1156be;
      text-align: center;
      &:nth-child(1) {
        background: #424242;
      }
      &:nth-child(2) {
        margin: 0 auto;
        width: 229px;
        margin-top: 50px;
        line-height: 64px;
        border-bottom: 1px solid #eeeeee;
        color: #222222;
      }
      &:nth-child(3) {
        line-height: 64px;
      }
    }
    .loca {
      width: 180px;
      height: 69px;
      background: #1156be;
      opacity: 0.8;
      position: absolute;
      top: 23px;
      left: 40px;
      p {
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #f6f6f6;
        margin-top: 10px;
      }
    }
  }
  .right {
    display: inline-block;
    box-shadow: 0px 0px 20px 0px rgba(47, 49, 51, 0.08);
    width: 850px;
    margin-left: 15px;
    padding: 24px 35px;
    img {
      width: 100%;
      &:nth-child(6) {
        margin-top: 54px;
      }
    }
    .p1 {
      text-align: center;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      margin-top: 42px;
    }
    .p2 {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: bold;
      color: #999999;
      margin-top: 17px;
    }
    .p3 {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 23px;
      letter-spacing: 0.9px;
      text-indent: 2em;
      &:nth-child(4) {
        margin-top: 59px;
      }
    }
  }
  .clearfix:after {
    content: ""; /*伪元素必须写的属性*/
    display: block; /*插入的元素必须是块级*/
    height: 0; /*不要看见这个元素*/
    clear: both; /*核心代码清除浮动*/
    visibility: hidden; /*不要看见这个元素*/
  }
}
</style>