<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>走进七星</p>
        <span class="xians"></span>
        <div class="ccde">
          <img src="@/assets/qixingp/矩形 958.png" alt="" />
          <p v-html="tableData.data"></p>
        </div>
      </div>
      <div class="cases">
        <p>我们的荣誉</p>
        <span class="xians"></span>
        <div class="ccde">
          <img src="@/assets/qixingp/6@2x.png" alt="" class="img1" />
          <img src="@/assets/qixingp/1@2x.png" alt="" class="img2" />
          <img src="@/assets/qixingp/4@2x.png" alt="" class="img3" />
          <img src="@/assets/qixingp/7@2x.png" alt="" class="img4" />
          <img src="@/assets/qixingp/5@2x.png" alt="" class="img1" />
          <img src="@/assets/qixingp/8@2x.png" alt="" class="img2" />
          <img src="@/assets/qixingp/9@2x.png" alt="" class="img3" />
          <img src="@/assets/qixingp/3@2x.png" alt="" class="img4" />
        </div>
      </div>
    </template>
    <template>
      <img src="" alt="" />
    </template>
    <bottom></bottom>
  </div>
</template>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_introduction", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data;
            console.log("that.tableData", that.tableData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0059ab;
    text-align: center;
  }
  .ccde {
    display: inline-block;
    margin: 0 auto;
    width: 19.281rem;
    margin-top: 1.375rem;
    img {
      width: 100%;
      height: 11.693rem;
    }
    p {
      text-indent: 2em;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #595757;
    }
  }
}
.case_i {
  width: 100%;
  height: 10rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.xians {
  display: block;
  width: 31px;
  height: 2px;
  background: #0059ab;
  margin: 0 auto;
  margin-top: 0.4375rem;
}
.cases {
  width: 100%;
  margin-top: 1.375rem;
  padding-top: 2rem;
  background: url("~@/assets/qixingp/矩形 1226.png");
  background-size: cover;
  p {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }
  .xians {
    display: block;
    width: 30px;
    height: 1px;
    background: #ffffff;
    margin: 0 auto;
  }
  .ccde {
    width: 18.5625rem;
    margin: 0 auto;
    margin-top: 1.0625rem;
    padding-bottom: 1.8125rem;
    .img1 {
      width: 2.5rem;
      height: 3.5rem;
      margin-right: 0.4rem;
    }
    .img2 {
      width: 4.75rem;
      height: 3.5rem;
      margin-right: 0.4rem;
    }
    .img3 {
      width: 5.3125rem;
      height: 3.5rem;
      margin-right: 0.4rem;
    }
    .img4 {
      width: 4.6875rem;
      height: 3.5rem;
    }
  }
}
</style>