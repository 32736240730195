<template>
  <div>
    <div class="wrap">
      <div class="tips">
        <div class="content">
          当前位置：<span
            @click="
              () => {
                this.$router.push('/');
              }
            "
            >网站首页</span
          >>><span
            @click="
              () => {
                this.$router.push('/newsCenter');
              }
            "
            >新闻中心</span
          >
          >><span>新闻中心详情</span>
        </div>
      </div>
      <div class="content">
        <div class="juzhong">
          <p>{{ list.title }}</p>
          <p>时间：{{ list.addtime }}</p>
        </div>
        <div class="words">
          <p v-html="list.content"></p>
          <!-- <img :src="'http://website.yuangtech.com' + list.cover" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import x from ''
export default {
  components: {},
  data() {
    return {
      tabName: "企业展厅",
      list: {},
    };
  },
  created() {
    this.getDetal();
  },
  methods: {
    getDetal() {
      const that = this;
      that.$http
        .postApi("/qx_news_detail", { id: that.$route.query.item })
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.list = res.data.data;
            console.log("that.list", that.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // this.getDetal();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-bottom: 2.81rem;
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  span {
    cursor: pointer;
  }
}
.title {
  margin-top: 89px;
  margin-bottom: 60px;
  text-align: center;
  .text {
    font-size: 30px;
    color: #333;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 29px;
  }
  .time {
    font-size: 20px;
    color: #999;
  }
}
.description {
  margin-bottom: 69px;
  font-size: 24px;
  line-height: 1.05;
  color: #666;
  text-align: left;
  text-indent: 2em;
  width: 100%;
  /deep/img {
    max-width: 100%;
  }
}

img {
  width: 100%;
  height: 605px;
  margin-bottom: 46px;
}
.juzhong {
  padding-top: 69px;
  width: 1200px;
  margin: 0 auto;
  p {
    &:nth-child(1) {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #595757;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      margin-top: 14px;
      color: #8b8b8b;
      text-align: center;
    }
  }
}
.words {
  width: 1200px;
  margin: 0 auto;
  margin-top: 38px;
  p {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #595757;
    line-height: 30px;
    text-indent: 2em;
  }
  img {
    width: 100%;
    height: 600px;
    margin-top: 19px;
    margin-bottom: 0px;
  }
}
</style>
