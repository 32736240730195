<template>
  <div>
    <Header></Header>
    <template>
      <div class="case_i">
        <img src="@/assets/qixingp/W_banner.jpg" alt="" />
      </div>
    </template>
    <template>
      <div class="case">
        <p>绿色展具案例</p>
        <span class="xians"></span>
        <div
          class="ccde"
          v-for="v in tableData"
          :key="v.id"
          @click="toDetail(v.id)"
        >
          <div class="pic">
            <img :src="'http://website.yuangtech.com' + v.cover" alt="" />
          </div>
          <div class="word">
            <p>{{ v.title }}</p>
            <p v-html="v.content"></p>
            <span>查看详情>></span>
          </div>
        </div>
      </div>
    </template>
    <bottom></bottom>
  </div>
</template>
  </div>
</template>


<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
//import x from ''
export default {
  components: { bottom, Header },
  data() {
    return {
      page: 1,
      total: 0,
      tableData: [],
      title: {
        top: "展览设计搭建",
        bottom: "一站式展会设计搭建服务，助力企业打造展会亮点",
      },
    };
  },
  created() {},
  methods: {
    pushRouter(item) {
      this.$router.push(item);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },

    toDetail(id) {
      this.$router.push({ path: "/exhibitionsDetail_s", query: { item: id } });
    },
    getData() {
      const that = this;
      const data = {
        page: that.page,
        limit: 6,
      };
      that.$http
        .postApi("/qx_zj_plan_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data.list;
            console.log("22222", that.tableData);
            that.total = res.data.data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.case {
  width: 19.281rem;
  margin: 0 auto;
  // margin-top: 1.5rem;
  p {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0059ab;
    text-align: center;
  }
  .ccde {
    display: inline-block;
    margin: 0 auto;
    width: 9.043rem;
    margin-top: 1.375rem;
    // background: #fff;
    margin-left: 0.9375rem;
    &:nth-child(2n-1) {
      margin-left: 0;
    }
    .pic {
      width: 9.043rem;
      height: 7.2625rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .word {
    padding: 1.5rem 1rem 2rem 1.5625rem;
    background: #f7f8f8;
    p {
      &:nth-child(1) {
        display: block;
        border-left: 2px solid #0059ab;
        text-align: left;
        margin-left: -0.5625rem;
        padding-left: 0.5rem;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0059ab;
      }
      &:nth-child(2) {
        font-size: 8px;
        display: block;
        width: 7.5rem;
        height: 2.875rem;
        overflow: hidden;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #595757;
        text-align: left;
        margin: 1.0625rem 0;
        text-indent: 2em;
        ::v-deep img {
          display: none;
        }
        ::v-deep p {
          background-color: #f7f8f8 !important;
          padding: 0px 0px !important;
          margin-right: 0.25rem;
        }
      }
    }
    span {
      background: url("~@/assets/qixingp/圆角矩形 1 拷贝.png") no-repeat;
      background-position: right;
      background-size: cover;
      width: 5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      display: block;
      text-align: center;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      float: right;
    }
  }
}
.pics {
  display: block;
  width: 6.625rem;
  height: 1.95rem;
  margin: 0 auto;
  margin-top: 2.625rem;
}
.case_i {
  width: 100%;
  height: 10rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.xians {
  display: block;
  width: 31px;
  height: 2px;
  background: #0059ab;
  margin: 0 auto;
  margin-top: 0.4375rem;
}
</style>