<template>
  <div>
    <div class="beijingt">
      <p class="lx">联系我们</p>
      <p class="yw">contact us</p>
      <p class="xian"></p>
      <p class="pbone">400 9660622</p>
    </div>
    <div class="tips">
      <div class="content">
        当前位置：<span
          @click="
            () => {
              this.$router.push('/');
            }
          "
          >网站首页</span
        >>>联系我们
      </div>
    </div>
    <div class="content">
      <div class="contact">
        <div>
          <img src="../../assets/qixingp/us_dianhua@2x.png" alt="" />
          <p>电话：{{ tableData.phone }}</p>
        </div>
        <div>
          <img src="../../assets/qixingp/us_dizhi@2x.png" alt="" />
          <p>地址：{{ tableData.address }}</p>
        </div>
        <div>
          <img src="../../assets/qixingp/us_youxiang@2x.png" alt="" />
          <p>邮箱：{{ tableData.email }}</p>
        </div>
      </div>
      <a
        href="https://www.amap.com/search?id=B0FFLG1NKX&city=370203&geoobj=119.225857%7C35.453842%7C122.129812%7C36.755613&query_type=IDQ&query=%E6%98%95%E8%8B%91%E4%B8%BD%E9%83%BDA%E5%BA%A7&zoom=9.45"
        target="_blank"
      >
        <img src="../../assets/qixingp/ditu@2x.png" class="bann" alt="" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: "",
      title: {
        top: "党政成果展厅",
        bottom: "数字型、多视角、成果型主题展馆",
      },
    };
  },
  mounted() {
    this.$bus.$emit("setTit", this.title);
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const that = this;
      const data = {};
      that.$http
        .postApi("/qx_company_phone", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.tableData = res.data.data;
            console.log("电话", that.tableData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.about {
  padding-bottom: 9.7rem;
}
.back {
  width: 100%;
  height: 471px;
  background: url("~@/assets/qixing/banner.png");
  p {
    font-size: 50px;
    font-family: SourceHanSansCN, SourceHanSansCN-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    &:nth-child(1) {
      padding-top: 123px;
    }
    &:nth-child(2) {
      font-size: 30px;
      font-family: SourceHanSansCN, SourceHanSansCN-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      margin-top: 17px;
    }
    &:nth-child(4) {
      margin-top: 40px;
    }
  }
  .xian {
    display: block;
    width: 100px;
    height: 8px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 34px;
  }
}
.tips {
  background-color: #eee;
  height: 78px;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 78px;
  margin-bottom: 56px;
  span {
    cursor: pointer;
  }
}
.bann {
  width: 100%;
  height: 540px;
  margin-bottom: 90px;
  margin-top: 109px;
}
.contact {
  display: flex;
  text-align: center;
  justify-content: space-around;
  img {
    width: 88px;
    height: 88px;
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    color: #333333;
    line-height: 30px;
    max-width: 295px;
  }
}
.beijingt {
  width: 100%;
  height: 471px;
  background: url("~@/assets/qixingp/us_banner@2x.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .lx {
    font-size: 50px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    letter-spacing: 2.5px;
  }
  .yw {
    font-size: 30px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    letter-spacing: 1.5px;
    margin-top: 17px;
  }
  .xian {
    margin-top: 34px;
    width: 100px;
    height: 8px;
    background: #ffffff;
  }
  .pbone {
    margin-top: 39px;
    font-size: 72px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    line-height: 18px;
  }
}
</style>
